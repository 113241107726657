import { Component } from '@angular/core';

@Component({
	selector: 'tp-header',
	template: `
		<header class="header">
			<h2>triadiprabowo</h2>
		</header>
	`
})
export class HeaderComponent { }
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  template: `
    <div class="container content-container" #content>
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-8 col-lg-6 content mr-2">
          <h2>About me</h2>
          <div class="row">
            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-6 d-flex justify-content-center align-items-center">
              <img class="headline" src="assets/img3.jpeg">
            </div>

            <div class="col-xs-12 col-sm-8 col-md-8 col-lg-6">
              <p>I am a Full Stack Developer, focused on Javascript (Angular, React, Node.js), Java Spring, SQL (Oracle, Postgre, MySQL), NoSQL (Mongo).</p>
              <p>I have passion in solving problem, learning new things, and gaming.</p>
              <p>Born at 4th January in Jakarta, Indonesia. Living happily with my wife in Antwerpen, Belgium.</p>
            </div>
          </div>
          
          <div class="row mt-5">
            <div class="col text-center">
              <h4>Connect with me on</h4>
              <ul class="icons">
                <li><a href="https://instagram.com/triadiprabowo" target="_blank"><fa-icon [icon]="fabInstagram"></fa-icon></a></li>
                <li><a href="https://facebook.com/triadiprabowo" target="_blank"><fa-icon [icon]="fabFacebook"></fa-icon></a></li>
                <li><a href="https://linkedin.com/in/triadiprabowo" target="_blank"><fa-icon [icon]="fabLinkedin"></fa-icon></a></li>
                <li><a href="https://twitter.com/triadiprabowo" target="_blank"><fa-icon [icon]="fabTwitter"></fa-icon></a></li>
                <li><a href="mailto:triadiprabowo@gmail.com" target="_blank"><fa-icon [icon]="faEmail"></fa-icon></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class AppComponent implements AfterViewInit {
  public fabInstagram = faInstagram;
  public fabFacebook = faFacebook;
  public fabLinkedin = faLinkedin;
  public fabTwitter = faTwitter;
  public faEmail = faEnvelope;

  @ViewChild('content', { read: ElementRef, static: true }) public content: ElementRef<HTMLElement>;

  constructor() {
    /**/
  }

  public ngAfterViewInit(): void {
    // init resizer
    this.onResize();

    // register listener
    this.listen();
  }

  private getCenterizedBox(): number {
    const windowHeight: number = window.innerHeight;
    const contentHeight: number = this.content.nativeElement.offsetHeight;

    const marginTop: number = (windowHeight - contentHeight) / 2;

    return marginTop;
  }

  private listen(): void {
    window.addEventListener('resize', this.onResize, true);
  }

  private onResize = (): void => {
    const marginTop: number = this.getCenterizedBox();
    this.content.nativeElement.style.marginTop = marginTop >= 0 ? `${marginTop}px` : '10px';
  }
}

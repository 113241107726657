import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
	imports: [
		CommonModule,
		FontAwesomeModule
	],
	exports: [
		CommonModule,
		FontAwesomeModule,

		// component
		HeaderComponent
	],
	declarations: [
		HeaderComponent
	]
})
export class SharedModule { }